import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../assets/testimonial (1).png";
import img2 from "../assets/testimonial (2).png";
import img3 from "../assets/testimonial (3).png";
import img4 from "../assets/testimonial (4).png";
import img5 from "../assets/testimonial (5).png";

function Testimonials() {
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: {
        max: 600,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 600,
      },
      items: 2,
      partialVisibilityGutter: 0,
    },
  };

  return (
    <section className="Testimonials">
      <h2>Testimonials</h2>
      <div className="Carousel">
        <Carousel
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={1000}
          centerMode={false}
          className="myTestimonial"
          containerClass="container-with-dots"
          dotListClass="dots"
          draggable={true}
          focusOnSelect={false}
          infinite={true}
          itemClass=""
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          sliderClass=""
          slidesToSlide={1}
          swipeable={true}
        >
          <div>
            <img src={img1} alt="Panenka FC testimonial" />
          </div>
          <div>
            <img src={img2} alt="Panenka FC testimonial" />
          </div>
          <div>
            <img src={img3} alt="Panenka FC testimonial" />
          </div>
          <div>
            <img src={img4} alt="Panenka FC testimonial" />
          </div>
          <div>
            <img src={img5} alt="Panenka FC testimonial" />
          </div>
        </Carousel>
      </div>
    </section>
  );
}

export default Testimonials;
